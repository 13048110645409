import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage, translations } from './LanguageContext';

const ExperienceItem = ({ title, period, description, achievements }) => (
  <motion.div 
    whileHover={{ scale: 1.02 }}
    className="border border-silvergray p-4 sm:p-8 hover:bg-white/5 transition duration-300"
  >
    <h3 className="text-xl sm:text-2xl font-light mb-2 text-silvergray tracking-wider">{title}</h3>
    <p className="text-bluegrey mb-4 font-light text-sm sm:text-base">{period}</p>
    <p className="text-gray-300 mb-6 font-light leading-relaxed text-sm sm:text-base">{description}</p>
    <ul className="list-disc list-inside text-gray-300 font-light space-y-2 text-sm sm:text-base">
      {achievements.map((achievement, index) => (
        <li key={index}>
          {achievement.main}
          {achievement.subpoints && (
            <ul className="list-disc list-inside text-gray-300 font-light ml-5 mt-2 space-y-1 text-xs sm:text-sm">
              {achievement.subpoints.map((subpoint, subIndex) => (
                <li key={subIndex}>{subpoint}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </motion.div>
);

const Experience = () => {
  const { language } = useLanguage();
  const t = translations[language];

  const experienceItems = [
    {
      title: t.aiDataScienceConsultant,
      period: t.aiDataScienceConsultantPeriod,
      description: t.aiDataScienceConsultantDesc,
      achievements: [
        {
          main: t.projectLead,
          subpoints: [
            t.projectLeadPoint1,
            t.projectLeadPoint2,
            t.projectLeadPoint3
          ]
        },
        {
          main: t.aiDrivenAnalysis,
          subpoints: [
            t.aiDrivenAnalysisPoint1,
            t.aiDrivenAnalysisPoint2,
            //t.aiDrivenAnalysisPoint3
          ]
        },
        {
          main: t.databaseDevelopment,
          subpoints: [
            t.databaseDevelopmentPoint1,
            t.databaseDevelopmentPoint2,
            t.databaseDevelopmentPoint3
          ]
        },
        {
          main: t.advancedMeasurement,
          subpoints: [
            t.advancedMeasurementPoint1,
            t.advancedMeasurementPoint2,
            t.advancedMeasurementPoint3
          ]
        },
        {
          main: t.projectManagement,
          subpoints: [
            t.projectManagementPoint1,
            t.projectManagementPoint2,
            t.projectManagementPoint3
          ]
        }
      ]
    },
    {
      title: t.mastersThesis,
      period: t.mastersThesisPeriod,
      description: t.mastersThesisDesc,
      achievements: []
    },
    {
      title: t.bachelorsThesis,
      period: t.bachelorsThesisPeriod,
      description: t.bachelorsThesisDesc,
      achievements: []
    }
  ];

  return (
    <section id="experience" className="py-16 sm:py-24 bg-gradient-to-r from-midnightplum to-black text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl sm:text-4xl font-light mb-12 sm:mb-16 text-center tracking-wider"
        >
          {t.professionalJourney}
        </motion.h2>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="space-y-8 sm:space-y-12 max-w-4xl mx-auto"
        >
          {experienceItems.map((item, index) => (
            <ExperienceItem
              key={index}
              title={item.title}
              period={item.period}
              description={item.description}
              achievements={item.achievements}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Experience;
// src/components/blogArticles.js
import { useLanguage, translations } from './LanguageContext';

const useBlogArticles = () => {
  const { language } = useLanguage();
  const articles = translations[language].articles;

  // In your original code you had a static array. 
  // Here’s a direct example mapping the numeric IDs plus the 'ai-game' key.
  const blogArticles = [
    {
      id: 1,
      title: articles[1].title,
      summary: articles[1].summary,
      mdFile: articles[1].mdFile,
    },
    {
      id: 2,
      title: articles[2].title,
      summary: articles[2].summary,
      mdFile: articles[2].mdFile,
    },
    {
      id: 3,
      title: articles[3].title,
      summary: articles[3].summary,
      mdFile: articles[3].mdFile,
    },
    // {
    //   id: 'ai-game',
    //   title: articles['ai-game'].title,
    //   summary: articles['ai-game'].summary,
    //   mdFile: articles['ai-game'].mdFile, 
    //   // For ai-game we likely won't have mdFile, but let's keep the prop in place
    // },
  ];

  return blogArticles;
};

export default useBlogArticles;
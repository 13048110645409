import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ImpressumView from './ImpressumView';

const Footer = () => {
  const [showImpressum, setShowImpressum] = useState(false);

  return (
    <>
      <footer className="bg-black text-white py-8">
        <div className="container mx-auto px-6 text-center font-light">
          <p>&copy; 2025 Alexander Warth. All rights reserved.</p>
          <button
            onClick={() => setShowImpressum(true)}
            className="text-white hover:text-gray-300 mt-2 inline-block"
          >
            Impressum
          </button>
        </div>
      </footer>

      <AnimatePresence>
        {showImpressum && (
          <ImpressumView onClose={() => setShowImpressum(false)} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Footer;

// src/components/ArticleView.js

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeAnimation from './CodeAnimation';
import AIGame from './AIGame';
import { useLanguage, translations } from './LanguageContext';

const ArticleView = ({ article, onClose }) => {
  const { language } = useLanguage();
  const t = translations[language];
  const [mdContent, setMdContent] = useState('');

  useEffect(() => {
    // Reset content when article changes
    setMdContent('');

    // If the article has a mdFile, fetch it
    if (article && article.mdFile) {
      fetch(`/articles/${article.mdFile}`)
        .then((res) => res.text())
        .then((text) => setMdContent(text))
        .catch((err) => console.error('Error fetching MD file:', err));
    }
  }, [article]);

  // If no article is selected, return null
  if (!article) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    >
      <div className="relative">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="relative bg-white text-black p-4 sm:p-8 w-full max-w-2xl max-h-[80vh] overflow-y-auto [&::-webkit-scrollbar]:w-3 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:bg-gray-800 [&::-webkit-scrollbar-thumb:hover]:bg-gray-900"
          style={{ 
            borderRadius: '0',
            scrollbarWidth: 'thin',
            scrollbarColor: '#111827'
          }}
        >
          <div className="sticky top-0 right-0 z-50">
            <button 
              onClick={onClose} 
              className="absolute right-0 p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-50 transition-colors"
            >
              <X size={24} />
            </button>
          </div>

        {/**
         * 1) If this is the AI Integration "article", show CodeAnimation
         * 2) If it's the AI Game "article", show AIGame
         * 3) Otherwise, we assume there's a mdFile with the actual content
         * 
         * NOTE: AI Integration and AI Game temporarily disabled
         */}
        {/* Temporarily disabled AI features
        {article.id === 'ai-integration' ? (
          <CodeAnimation />
        ) : article.id === 'ai-game' ? (
          <div className="game-container">
            <AIGame />
          </div>
        ) : (
        */}
        {(
          // Render the fetched Markdown with Tailwind typography styling
          <div className="prose max-w-none text-gray-700 font-light text-base sm:text-lg leading-relaxed tracking-wider [&>h1]:font-light [&>h2]:font-light [&>h3]:font-light [&>h4]:font-light [&>h5]:font-light [&>h6]:font-light [&>h1]:tracking-wider [&>h2]:tracking-wider [&>h3]:tracking-wider [&>h4]:tracking-wider [&>h5]:tracking-wider [&>h6]:tracking-wider [&>h1]:text-lg [&>h1]:sm:text-xl [&>h2]:text-lg [&>h2]:sm:text-xl [&>h3]:text-lg [&>h3]:sm:text-xl [&_img]:max-w-full [&_img]:h-auto [&_img]:mx-auto [&_img]:my-4 [&_img]:shadow-md [&_img]:rounded-none">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {mdContent}
            </ReactMarkdown>
          </div>
        )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ArticleView;

import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export const translations = {
  en: {
    // Hero
    heroTitle: "AI Solutions Architect & Technical Innovation",
    heroSubtitle:
      "Building tomorrow's solutions through GPTs and autonomous agents - tailored for your business",
    heroButton: "Explore AI Solutions",

    // About
    aboutTitle: "About Alexander Warth",
    aboutParagraph1:
      "I gained extensive engineering and research experience at the GSI Helmholtzzentrum für Schwerionenforschung GmbH, supported by an academic background in Applied Physics (M.Sc.) and General Mechanical Engineering (B.Eng.). My skill set spans advanced software architecture, HPC environments, and precision engineering—enabling me to create highly effective, future-ready solutions.",
    aboutParagraph2:
      "As a freelance AI consultant, I specialize in GPT/LLM technologies, agentic workflows, and intelligent automation. Whether you're optimizing processes, reinventing product lines, or overhauling entire business models, I guide organizations through a tailored technological evolution founded on expertise, practicality, and innovation.",

    aiIntegrationTitle: "AI Integration",
    aiIntegrationDesc:
      "Deploying sophisticated GPT and LLM-based solutions to elevate decision-making, streamline communication, and unlock innovation across your organization.",
    workflowAutomationTitle: "Workflow Automation",
    workflowAutomationDesc:
      "Designing and implementing automated processes powered by advanced language models and AI, reducing manual overhead and boosting efficiency.",
    techConsultationTitle: "Technology Consultation",
    techConsultationDesc:
      "Providing expert guidance on adopting and integrating novel AI strategies—including agentic workers—tailored to your business goals and industry demands.",
    
      // AI Expertise
    aiExpertiseTitle: "AI Expertise",
 
    aiSolutions: "GPT/LLM Solutions",
    aiSolutionsDesc:
      "Customized implementations using GPT-o1, Claude, Gemini, and open-source models through OpenRouter that redefine industry norms, from customer-facing chatbots to internal knowledge bases, including locally hosted solutions.",
    
    intelligentAutomation: "Intelligent Process Automation",
    intelligentAutomationDesc:
      "Building AI-driven workflows powered by open-source models like Mixtral and LLaMA, combined with custom integrations to minimize human error and optimize data processing across your organization.",
    
    aiStrategyIntegration: "AI Strategy Integration",
    aiStrategyIntegrationDesc:
      "Developing comprehensive AI roadmaps incorporating OpenAI, Anthropic, and open-source solutions via OpenRouter, ensuring cost-effective scaling and seamless alignment with your company's strategic vision.",
    
    advancedDataAnalytics: "Advanced Data Analytics",
    advancedDataAnalyticsDesc:
      "Applying state-of-the-art techniques using Python, TensorFlow, and Pandas to derive actionable insights and create compelling visualizations from complex datasets.",
    
    promptEngineering: "Prompt Engineering",
    promptEngineeringDesc:
      "Crafting highly effective prompts and interaction flows using LangChain, embedding fine-tuning, and RAG (Retrieval-Augmented Generation) techniques across various model providers through unified APIs.",
    
    aiDrivenProcessOptimization: "Intelligent Process Optimization",
    aiDrivenProcessOptimizationDesc:
      "Leveraging autonomous AI agents through Microsoft AutoGen and other open-source frameworks to refine operational workflows, reduce costs, and accelerate innovation with minimal human intervention.",

    softwareSkillsTitle: "Development, Engineering, and Simulation Skills",
    programmingScripting: "Programming & Scripting",
    programmingScriptingDesc:
      "Proficient in Python, C++, SQL, LaTeX, VBA, and Bash, essential for robust GPT integrations, data pipelines, and automation solutions.",
    developmentTools: "Development Tools",
    developmentToolsDesc:
      "Skilled with Docker, Podman, Vagrant, and Git for efficient containerization, virtualization, and version control, key in modern AI deployments.",
    databaseSystems: "Database Systems",
    databaseSystemsDesc:
      "Experienced in MS Access and PostgreSQL for designing, managing, and optimizing databases to support AI-driven applications.",
    caeCfdFemSoftware: "CAE/CFD/FEM Software",
    caeCfdFemSoftwareDesc:
      "Accomplished with COMSOL, ANSYS, and OpenFOAM for advanced simulations and engineering analyses in complex technical systems.",
    cadSoftware: "CAD Software",
    cadSoftwareDesc:
      "Proficient in CATIA, Inventor, Eagle, and KiCad for intricate mechanical and electronic design and technical documentation.",
    mathAnalysisTools: "Mathematics & Analysis Tools",
    mathAnalysisToolsDesc:
      "Advanced user of Matlab/Octave, Maple, and Mathematica for complex modeling and data analysis in engineering applications.",

    // Experience
    professionalJourney: "Professional Journey",
    aiDataScienceConsultant: "Scientific Staff Member – GSI Darmstadt",
    aiDataScienceConsultantPeriod: "2017 - Present",
    aiDataScienceConsultantDesc:
          "As a scientific staff member and engineer at GSI Darmstadt, I specialize in advanced research and development initiatives, including SIS100 magnet module testing and the engineering of high-tech measurement solutions. My work encompasses Python-based automation, data analytics, and software optimization—all within a high-performance computing environment.",

    projectLead: "Project Lead: 'Improvement of Series Test Facility Software and Hardware Infrastructure'",
    projectLeadPoint1:
      "Overhauled software repository structures and optimized complex engineering software for SIS100 series testing.",
    projectLeadPoint2:
      "Resolved critical hardware issues to ensure stable, continuous data acquisition and test facility operations.",
    projectLeadPoint3:
      "Introduced collaborative platforms (e.g., Git, Nextcloud) and streamlined workflows, boosting team efficiency and communication.",
    
    aiDrivenAnalysis: "Advanced Analysis and Automation:",
    aiDrivenAnalysisPoint1:
      "Developed Python and Bash-based automation for SIS100 measurements, enhancing data accuracy and reducing manual overhead.",
    aiDrivenAnalysisPoint2:
      "Leveraged GSI HPC clusters (e.g., Virgo) to accelerate large-scale simulations and in-depth data processing.",
    aiDrivenAnalysisPoint3:
      "",
    
    databaseDevelopment: "Database Development and Management:",
    databaseDevelopmentPoint1:
      "Created a multi-user MS Access database for Non-Conformity Reports, enabling cross-project collaboration and efficient data handling.",
    databaseDevelopmentPoint2:
      "Designed user-friendly interfaces and robust back-end structures for multidimensional data, aligning with CERN EDMS integration.",
    databaseDevelopmentPoint3:
      "Implemented automated data import/export routines, ensuring seamless interoperability with existing systems.",
    
    advancedMeasurement: "Advanced Measurement Systems:",
    advancedMeasurementPoint1:
      "Engineered contactless measurement solutions (capacitive sensors, laser trackers) for precise magnet aperture geometry analysis.",
    advancedMeasurementPoint2:
      "Developed custom control software in Octave/Python, adapted for HPC execution and scalable data analysis.",
    advancedMeasurementPoint3:
      "Contributed to cryogenic measurement system design, integrating novel sensor technology and advanced data acquisition frameworks.",
    
    projectManagement: "Project Management and Leadership:",
    projectManagementPoint1:
      "Coordinated software and hardware development for complex engineering projects, aligning milestones with organizational goals.",
    projectManagementPoint2:
      "Managed cross-functional teams, optimizing resource usage and fostering clear communication through structured task assignment.",
    projectManagementPoint3:
      "Oversaw timelines, budget allocations, and risk assessments, ensuring on-time project delivery across multiple parallel efforts.",
    
    mastersThesis: "Master's Thesis – GSI Darmstadt",
    mastersThesisPeriod: "2016",
    mastersThesisDesc:
      "Conducted in-depth research on 'Pre-treatment Procedures for Minimizing Ion Beam Induced Desorption,' building custom analysis software and overseeing experiments relevant to high-precision physics.",
    bachelorsThesis: "Bachelor's Thesis – GSI Darmstadt",
    bachelorsThesisPeriod: "2013",
    bachelorsThesisDesc:
      "Explored 'Analysis of the Thermodynamic Behavior of a Pump Cold-Warm Transition,' including simulations and data analysis foundational to AI-enabled engineering approaches.",

    // Contact
    connectWithMe: "Connect with Me",
    connectDesc:
      "Ready to explore GPT solutions or elevate your automation processes? Let’s discuss how cutting-edge language models, agentic AI, and strategic consulting can transform your business, drive innovation, and empower your teams.",

    // Impressum
    impressum: "Impressum",
    contactInformation: "Contact Information",
    legalInformation: "Legal Information",
    responsibleForContent: "Responsible for Content: Alexander Warth - alexander.warth[aet]mailbox.org",
    impressumCompliance:
      "This Impressum complies with German law requirements for website legal notices.",

    // Insights
    latestInsights: "Latest Insights",
    readMore: "Read More",
    aiIntegration: "AI Integration",
    articles: {
      1: {
        title: "Revolutionizing Knowledge Access with RAG",
        summary: "Explore how RAG is transforming enterprise search...",
        mdFile: "rag-article.en.md"
      },
      2: {
        title: "The Rise of Agentic Workers: Autonomous AI Systems in Action",
        summary: "Discover how autonomous AI agents are revolutionizing workflow automation and decision-making processes in modern organizations.",
        mdFile: "workers-article.en.md"
      },
      3: {
        title: "Local LLMs: Harnessing AI's Power on Your Own Hardware",
        summary: "Explores the potential of running advanced AI models locally, detailing benefits like enhanced privacy, cost savings, and reduced latency, alongside practical setup steps.",
        mdFile: "Localllms.en.md"   
      },
      "ai-game": {
        title: "Interactive AI-Generated Game",
        summary:
          "Experience AI in action with our dynamic, input-driven game.",
        content: ""
      }
    }
  },

  de: {
    // Hero
    heroTitle: "KI-Lösungsarchitekt & technische Innovation",
    heroSubtitle:
      "Die Lösungen von morgen entwickeln durch GPTs und autonome Agenten – maßgeschneidert für Ihr Unternehmen",
    heroButton: "KI-Lösungen entdecken",

    // About
    aboutTitle: "Über Alexander Warth",
    aboutParagraph1:
      "Ich habe umfangreiche Ingenieur- und Forschungserfahrung am GSI Helmholtzzentrum für Schwerionenforschung GmbH gesammelt, unterstützt durch einen akademischen Hintergrund in Angewandter Physik (M.Sc.) und Allgemeinem Maschinenbau (B.Eng.). Mein Skillset reicht von fortschrittlicher Softwarearchitektur, HPC-Umgebungen bis hin zu Präzisionstechnik – wodurch ich hocheffektive, zukunftsorientierte Lösungen schaffen kann.",
    aboutParagraph2:
      "Als freiberuflicher KI-Berater spezialisiere ich mich auf GPT-/LLM-Technologien, agentenbasierte Workflows und intelligente Automatisierung. Ob Sie Prozesse optimieren, Produktlinien neu erfinden oder ganze Geschäftsmodelle umgestalten möchten – ich begleite Unternehmen auf einem individuell zugeschnittenen technologischen Entwicklungspfad, der auf Fachkompetenz, Praxisnähe und Innovationskraft beruht.",

    aiIntegrationTitle: "KI-Integration",
    aiIntegrationDesc:
      "Einsatz fortschrittlicher GPT- und LLM-basierter Lösungen, um Entscheidungsfindung zu verbessern, Kommunikation zu vereinfachen und Innovation in Ihrem Unternehmen zu fördern.",
    workflowAutomationTitle: "Workflow-Automatisierung",
    workflowAutomationDesc:
      "Entwicklung und Implementierung automatisierter Prozesse, angetrieben von fortgeschrittenen Sprachmodellen und KI, zur Reduzierung manueller Arbeit und Steigerung der Effizienz.",
    techConsultationTitle: "Technologieberatung",
    techConsultationDesc:
      "Beratung bei der Einführung und Integration neuartiger KI-Strategien – einschließlich agentenbasierter Systeme – maßgeschneidert auf Ihre Geschäftsziele und Branchenanforderungen.",
    
    // KI-Expertise
    aiExpertiseTitle: "KI-Expertise",

    aiSolutions: "GPT/LLM-Lösungen",
    aiSolutionsDesc:
      "Kundenspezifische Implementierungen mit GPT-4, Claude, Gemini und Open-Source-Modellen über OpenRouter, die Branchenstandards neu definieren – von kundenorientierten Chatbots bis zu internen Wissensdatenbanken, einschließlich lokal gehosteter Lösungen.",
    
    intelligentAutomation: "Intelligente Prozessautomatisierung",
    intelligentAutomationDesc:
      "Aufbau KI-gesteuerter Workflows mithilfe von Open-Source-Modellen wie Mixtral und LLaMA, kombiniert mit maßgeschneiderten Integrationen, um menschliche Fehler zu minimieren und die Datenverarbeitung in Ihrem Unternehmen zu optimieren.",
    
    aiStrategyIntegration: "KI-Strategie-Integration",
    aiStrategyIntegrationDesc:
      "Entwicklung umfassender KI-Roadmaps unter Einbeziehung von OpenAI, Anthropic und Open-Source-Lösungen über OpenRouter, um eine kosteneffiziente Skalierung und nahtlose Anpassung an die strategische Vision Ihres Unternehmens sicherzustellen.",
    
    advancedDataAnalytics: "Fortgeschrittene Datenanalyse",
    advancedDataAnalyticsDesc:
      "Anwendung modernster Techniken mit Python, TensorFlow und Pandas, um aus komplexen Datensätzen verwertbare Erkenntnisse zu gewinnen und überzeugende Visualisierungen zu erstellen.",
    
    promptEngineering: "Prompt-Engineering",
    promptEngineeringDesc:
      "Erstellung hoch effektiver Prompts und Interaktionsflüsse mithilfe von LangChain, Embedding-Feintuning und RAG (Retrieval-Augmented Generation) Techniken über verschiedene Modellanbieter hinweg durch einheitliche APIs.",
    
    aiDrivenProcessOptimization: "Intelligente Prozessoptimierung",
    aiDrivenProcessOptimizationDesc:
      "Nutzung autonomer KI-Agenten über Microsoft AutoGen und andere Open-Source-Frameworks, um operative Workflows zu optimieren, Kosten zu reduzieren und Innovation mit minimalem menschlichen Eingriff zu beschleunigen.",

    softwareSkillsTitle: "Entwicklungs-, Ingenieur- und Simulationskompetenzen",
    programmingScripting: "Programmierung & Scripting",
    programmingScriptingDesc:
      "Beherrschung von Python, C++, SQL, LaTeX, VBA und Bash, essentiell für robuste GPT-Integrationen, Datenpipelines und Automatisierungslösungen.",
    developmentTools: "Entwicklungstools",
    developmentToolsDesc:
      "Kompetent im Umgang mit Docker, Podman, Vagrant und Git für effiziente Containerisierung, Virtualisierung und Versionskontrolle, entscheidend für moderne KI-Deployments.",
    databaseSystems: "Datenbanksysteme",
    databaseSystemsDesc:
      "Erfahrung mit MS Access und PostgreSQL für Design, Verwaltung und Optimierung von Datenbanken zur Unterstützung KI-gesteuerter Anwendungen.",
    caeCfdFemSoftware: "CAE/CFD/FEM-Software",
    caeCfdFemSoftwareDesc:
      "Versiert in COMSOL, ANSYS und OpenFOAM für fortgeschrittene Simulationen und Ingenieursanalysen in komplexen technischen Systemen.",
    cadSoftware: "CAD-Software",
    cadSoftwareDesc:
      "Erfahren in CATIA, Inventor, Eagle und KiCad für detaillierte mechanische und elektronische Konstruktionen sowie technische Dokumentation.",
    mathAnalysisTools: "Mathematik- & Analysewerkzeuge",
    mathAnalysisToolsDesc:
      "Fortgeschrittener Anwender von Matlab/Octave, Maple und Mathematica für komplexe Modellierung und Datenanalyse in ingenieurtechnischen Anwendungen.",
    
    // Experience
    professionalJourney: "Beruflicher Werdegang",
    aiDataScienceConsultant: "Wissenschaftlicher Mitarbeiter – GSI Darmstadt",
    aiDataScienceConsultantPeriod: "2017 - Gegenwart",
    aiDataScienceConsultantDesc:
      "Als wissenschaftlicher Mitarbeiter und Ingenieur am GSI Helmholtzzentrum für Schwerionenforschung GmbH in Darmstadt spezialisiere ich mich auf fortschrittliche Forschungs- und Entwicklungsinitiativen, einschließlich der Prüfung von SIS100-Magnetmodulen und der Entwicklung hochmoderner Messlösungen. Meine Arbeit umfasst Python-basierte Automatisierung, Datenanalyse und Softwareoptimierung – alles in einer Hochleistungsrechnerumgebung.",

    projectLead: "Projektleitung: 'Verbesserung der Software- und Hardware-Infrastruktur der Serientesteinrichtung'",
    projectLeadPoint1:
      "Umstrukturierung von Software-Repository-Strukturen und Optimierung komplexer Engineering-Software für die SIS100-Serientests.",
    projectLeadPoint2:
      "Lösung kritischer Hardwareprobleme zur Sicherstellung eines stabilen, kontinuierlichen Datenzugriffs und Betriebs der Testeinrichtung.",
    projectLeadPoint3:
      "Einführung kollaborativer Plattformen (z.B. Git, Nextcloud) und Optimierung von Workflows, wodurch Team Effizienz und Kommunikation gesteigert wurden.",
    
    aiDrivenAnalysis: "Fortschrittene Analyse und Automatisierung:",
    aiDrivenAnalysisPoint1:
      "Entwicklung von Python- und Bash-basierter Automatisierung für SIS100-Messungen, Verbesserung der Datenqualität und Reduzierung manuellen Aufwands.",
    aiDrivenAnalysisPoint2:
      "Nutzung der GSI-HPC-Cluster (z.B. Virgo), um groß angelegte Simulationen und tiefgehende Datenverarbeitung zu beschleunigen.",
    aiDrivenAnalysisPoint3:
      "",
    
    databaseDevelopment: "Datenbankentwicklung und -management:",
    databaseDevelopmentPoint1:
      "Erstellung einer Multi-User-MS-Access-Datenbank für Non-Conformity Reports, die projektübergreifende Zusammenarbeit und effiziente Datenhandhabung ermöglicht.",
    databaseDevelopmentPoint2:
      "Design benutzerfreundlicher Interfaces und robuster Back-End-Strukturen für multidimensionale Daten, abgestimmt auf CERN EDMS-Integration.",
    databaseDevelopmentPoint3:
      "Implementierung automatisierter Datenimport/-export-Routinen, um nahtlose Interoperabilität mit bestehenden Systemen zu gewährleisten.",
    
    advancedMeasurement: "Fortschrittliche Messsysteme:",
    advancedMeasurementPoint1:
      "Entwicklung kontaktloser Messlösungen (kapazitive Sensoren, Lasertracker) für präzise Analyse der Magnetaperturgeometrie.",
    advancedMeasurementPoint2:
      "Entwicklung kundenspezifischer Kontrollsoftware in Octave/Python, angepasst für HPC-Ausführung und skalierbare Datenanalyse.",
    advancedMeasurementPoint3:
      "Beitrag zum Design des kryogenen Messsystems, Integration neuartiger Sensortechnologie und fortschrittlicher Datenakquisitions-Frameworks.",
    
    projectManagement: "Projektmanagement und Führung:",
    projectManagementPoint1:
      "Koordination von Software- und Hardwareentwicklung für komplexe Engineering-Projekte, Ausrichtung von Meilensteinen mit den Zielen der Organisation.",
    projectManagementPoint2:
      "Management funktionsübergreifender Teams, Optimierung der Ressourcennutzung und Förderung klarer Kommunikation durch strukturierte Aufgabenverteilung.",
    projectManagementPoint3:
      "Überwachung von Zeitplänen, Budgetzuweisungen und Risikobewertungen, um eine pünktliche Projektauslieferung über mehrere parallele Bemühungen hinweg sicherzustellen.",
    
    mastersThesis: "Masterarbeit – GSI Darmstadt",
    mastersThesisPeriod: "2016",
    mastersThesisDesc:
      "Durchführung eingehender Forschung zu 'Vorbehandlungsverfahren zur Minimierung der ionenstrahlinduzierten Desorption', Entwicklung kundenspezifischer Analyse-Software und Leitung von Experimenten, die für hochpräzise Physik relevant sind.",
    bachelorsThesis: "Bachelorarbeit – GSI Darmstadt",
    bachelorsThesisPeriod: "2013",
    bachelorsThesisDesc:
      "Untersuchung 'Analyse des thermodynamischen Verhaltens eines Pumpen-Kalt-Warm-Übergangs', einschließlich Simulationen und Datenanalyse, grundlegend für KI-unterstützte Engineering-Ansätze.",

    // Contact
    connectWithMe: "Kontaktieren Sie mich",
    connectDesc:
      "Möchten Sie GPT-Lösungen erkunden oder Ihre Automatisierungsprozesse optimieren? Lassen Sie uns besprechen, wie modernste Sprachmodelle, agentenbasierte KI und strategische Beratung Ihr Unternehmen transformieren, Innovation vorantreiben und Ihre Teams stärken können.",

    // Impressum
    impressum: "Impressum",
    contactInformation: "Kontaktinformationen",
    legalInformation: "Rechtliche Informationen",
    responsibleForContent: "Verantwortlich für den Inhalt: Alexander Warth - alexander.warth[aet]mailbox.org",
    impressumCompliance:
      "Dieses Impressum entspricht den gesetzlichen Anforderungen für rechtliche Hinweise auf Websites in Deutschland.",

    // Insights
    latestInsights: "Neueste Erkenntnisse",
    readMore: "Weiterlesen",
    aiIntegration: "KI-Integration",
    articles: {
      1: {
        title: "Die Revolution des Wissenszugangs mit RAG",
        summary: "Erkunden Sie, wie RAG die Unternehmenssuche transformiert...",
        mdFile: "rag-article.de.md"
      },
      2: {
        title: "Der Aufstieg agentenbasierter Systeme: Autonome KI-Systeme in Aktion",
        summary: "Erfahren Sie, wie autonome KI-Agenten die Workflow-Automatisierung und Entscheidungsprozesse in modernen Organisationen revolutionieren.",
        mdFile: "workers-article.de.md"
      },
      3: {
        title: "Lokale LLMs: Die Kraft der KI auf Ihrer eigenen Hardware nutzen",
        summary: "Erforschen Sie das Potenzial, fortschrittliche KI-Modelle lokal auszuführen, mit Vorteilen wie erhöhter Privatsphäre, Kosteneinsparungen und reduzierter Latenz sowie praktischen Einrichtungsanleitungen.",
        mdFile: "Localllms.de.md"
      },
      "ai-game": {
        title: "Interaktives KI-generiertes Spiel",
        summary:
          "Erleben Sie KI in Aktion mit unserem dynamischen, eingabegesteuerten Spiel.",
        content: ""
      }
    }
  }
};
